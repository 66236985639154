import React, { ReactNode } from "react"
import styled from "styled-components"

type ISize = "small" | "default"
interface IProps {
  children: ReactNode
  size?: ISize
  className?: string
  id?: string
}

const ContentWrapper = ({
  children,
  size = "default",
  className,
  id,
}: IProps) => (
  <Container id={id} className={className} size={size}>
    {children}
  </Container>
)

const Container = styled.div`
  max-width: ${({ size }: { size: ISize }) =>
    size === "small" ? `900px` : `1400px`};
  margin: 0 auto;
  padding: 0 24px;
`

export default ContentWrapper
