import { css } from "styled-components"

export const fastDuration = 240
export const slowDuration = 400

export const fastTransition = `${fastDuration}ms ease-in-out`
export const slowTransition = `${slowDuration}ms ease-in-out`

export const fadeIn = css<{ animationDelay?: number }>`
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  opacity: 0;
  animation-name: fadeInAnimation;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  ${({ animationDelay }) =>
    !!animationDelay && `animation-delay: ${animationDelay}ms;`}
`

export const fadeInUp = css<{ animationDelay?: number }>`
  @keyframes fadeInUpAnimation {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  opacity: 0;
  animation-name: fadeInUpAnimation;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  ${({ animationDelay }) =>
    !!animationDelay && `animation-delay: ${animationDelay}ms;`}
`
